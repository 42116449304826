<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ $t("bankAccount.bankAccount") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-start custom-input whitebg-input m-2">
            <div class="mb-3">
              <label class="form-label bold-12 text-gray">{{ $t("bankAccount.currency") }}</label>
              <select v-model="currency" class="form-control">
                <option
                  v-for="(c, i) in enabledCurrencies"
                  :key="i"
                  :value="c.code"
                >
                  {{ c.code }}
                </option>
              </select>
            </div>
            <div class="mb-5 mt-4">
              <label class="cstm-check d-flex">
                <span class="regular-12 text-black mx-3">
                  {{ $t("bankAccount.thisIsTheDefaultCurrency") }}
                </span>
                <input
                  type="checkbox"
                  v-model="value[currency].default"
                  :value="1"
                  v-on:change="defaultCheck(currency)"
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <div
              class="mb-3"
              :class="{
                valid: (key === 'number' || key === 'iban') && valid,
                error: (key === 'number' || key === 'iban') && valid === false,
              }"
              v-for="(val, key) in currencies[
                currencies[currency] !== undefined ? currency : 'default'
              ].fields"
              :key="key"
            >
              <label class="form-label bold-12 text-gray">{{ val }}</label>
              <input
                type="text"
                v-model="value[currency][key]"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" v-on:click="save(true)">
            {{ $t("finance.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import BankCurrencies from "@/modules/BankCurrencies";

const bootstrap = require("bootstrap");

export default {
  name: "BankAccount",
  props: {
    modelValue: Object,
    show: Boolean,
  },
  emits: ["update:modelValue", "update:show", "change"],
  data: function () {
    const store = useStore();
    let enabledCurrencies = store.state.currencies;
    let values = {};
    for (const currency of enabledCurrencies) {
      values[currency.code] =
        this.modelValue[currency.code] !== undefined
          ? this.modelValue[currency.code]
          : {};
    }

    return {
      needSave: false,
      saveTime: null,
      value: values,
      myModal: null,
      currency: this.modelValue?.currency || "EUR",
      currencies: BankCurrencies,
    };
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      this.myModal.show();
    } else {
      this.myModal.hide();
    }
  },
  computed: {
    enabledCurrencies() {
      const store = useStore();
      return store.state.currencies;
    },
    valid() {
      if (this.currency === "HUF") {
        return this.checkHUF();
      } else if (
        this.currencies[
          this.currencies[this.currency] !== undefined
            ? this.currency
            : "default"
        ].fields.iban !== undefined
      ) {
        return this.checkIban();
      }
      return null;
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        if (this.currency === "HUF") {
          if (
            this.value?.HUF?.number?.length > 8 &&
            this.value?.HUF?.number[8] !== "-" &&
            this.value?.HUF?.number[7] !== "-"
          ) {
            this.value.HUF.number =
              this.value.HUF?.number?.substr(0, 8) +
              "-" +
              this.value.HUF?.number?.substr(8, 20);
          }
          if (
            this.value?.HUF?.number?.length > 17 &&
            this.value?.HUF?.number[17] !== "-" &&
            this.value?.HUF?.number[16] !== "-"
          ) {
            this.value.HUF.number =
              this.value.HUF?.number?.substr(0, 17) +
              "-" +
              this.value.HUF?.number?.substr(17, 8);
          }
        }

        this.needSave = true;
        if (this.saveTime === null) {
          this.saveTime = setTimeout(this.save, 2000);
        }
      },
    },
    show() {
      this.myModal = new bootstrap.Modal(this.$refs.modal, {
        backdrop: "static",
      });
      if (this.show) {
        this.myModal.show();
      } else {
        this.myModal.hide();
      }
    },
  },
  methods: {
    defaultCheck(currency) {
      for (const key in this.value) {
        if (this.value[key].default && key !== currency) {
          this.value[key].default = false;
        }
      }
    },
    save(close = false) {
      if (this.needSave) {
        this.$emit("update:modelValue", this.value);
        this.$emit("change", this.value);

        this.needSave = false;
        if (this.saveTime !== null) {
          clearInterval(this.saveTime);
          this.saveTime = null;
        }
      }
      if (close) {
        this.myModal.hide();
        this.$emit("update:show", false);
      }
    },
    checkIban() {
      var CODE_LENGTHS = {
        AD: 24,
        AE: 23,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BG: 22,
        BH: 22,
        BR: 29,
        CH: 21,
        CY: 28,
        CZ: 24,
        DE: 22,
        DK: 18,
        DO: 28,
        EE: 20,
        ES: 24,
        FI: 18,
        FO: 18,
        FR: 27,
        GB: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        MC: 27,
        MD: 24,
        ME: 22,
        MK: 19,
        MR: 27,
        MT: 31,
        MU: 30,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        SA: 24,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        TN: 24,
        TR: 26,
        AL: 28,
        BY: 28,
        EG: 29,
        GE: 22,
        IQ: 23,
        LC: 32,
        SC: 31,
        ST: 25,
        SV: 28,
        TL: 23,
        UA: 29,
        VA: 22,
        VG: 24,
        XK: 20,
      };
      var iban = String(this.value[this.currency].iban)
          .toUpperCase()
          .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
        code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
        digits;

      if (!code || CODE_LENGTHS[code[1]] === undefined) {
        return null;
      }

      // check syntax and length
      if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
      }
      // rearrange country code and check digits, and convert chars to ints
      digits = (code[3] + code[1] + code[2]).replace(
        /[A-Z]/g,
        function (letter) {
          return letter.charCodeAt(0) - 55;
        }
      );
      // final check
      return this.mod97(digits) === 1;
    },
    mod97(string) {
      var checksum = string.slice(0, 2),
        fragment;
      for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
      }
      return checksum;
    },
    checkHUF() {
      if (
        this.value?.HUF?.number === undefined ||
        this.value?.HUF?.number === null
      ) {
        return;
      }
      const bankAccount = this.value?.HUF?.number;
      let sStrippedAccount = bankAccount.replace(/-/g, "");
      const sCheckTags = "9731";
      if (sStrippedAccount.length < 16) {
        return null;
      }
      if (sStrippedAccount.length === 16 || sStrippedAccount.length === 24) {
        let cs = 0;
        for (let i = 0; i < 8; i++) {
          cs += sStrippedAccount[i] * sCheckTags[i % 4];
        }
        if (!(cs % 10)) {
          cs = 0;
          for (let i = 8; i < sStrippedAccount.length; i++) {
            cs += sStrippedAccount[i] * sCheckTags[i % 4];
          }
          return !(cs % 10);
        }
      }
      return false;
    },
  },
  components: {},
};
</script>
